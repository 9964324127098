<template>
  <div>
    <AllPage ref="AllPage" :allProps="allProps">
      <template v-slot:type="scope">
        <span
          v-for="item in typeList"
          :key="item.value"
          v-show="scope.data.type === item.value"
          >{{ item.label }}</span
        >
      </template>
      <template v-slot:state="scope">
        <span
          v-for="item in stateList"
          :key="item.value"
          v-show="scope.data.state === item.value"
          >{{ item.label }}</span
        >
      </template>
      <template v-slot:operation="scope">
        <el-button
          size="small"
          @click.native.prevent="dialogForm('查看', scope.data)"
        >
          查看
        </el-button>
        <el-button
          size="small"
          @click.native.prevent="isAgree(scope.data.order_id,'同意')"
          v-if="scope.data.state==0"
        >
          同意
        </el-button>
        <el-button
          size="small"
          @click.native.prevent="isAgree(scope.data.order_id,'驳回')"
          v-if="scope.data.state==0"
        >
          驳回
        </el-button>
      </template>
      <!-- <template v-slot:imgList="scope">
        <el-image
            style="width: 100px; height: 100px"
            :src="scope.data.imgList[0].url"
            :preview-src-list="scope.data ? scope.data.imgListC: ''"
          >  </el-image>
      </template> -->
      
    </AllPage>
  </div>
</template>
<script>
import { Api } from "@/apis";
import { allProps } from "./config";
import { messageBox } from "@/utils/messageBox";
export default {
  components: {
    AllPage: () => import("@/components/AllPage/AllPage.vue"),
  },
  provide() {
    return {
      changeRuleForm: function(){}
    };
  },
  data() {
    return {
      tableData: {},
      allProps,
      Api,
      typeList: [
        { label: "设备硬件故障", value: 1 },
        { label: "游戏问题", value: 2 },
        { label: "其他", value: 3 },
      ],
      stateList: [
        { label: "创建成功", value: 0 },
        { label: "取消", value: 1 },
        { label: "已处理", value: 2 },
        { label: "驳回", value: 3 },
      ],
    };
  },
  created() {
    this.allProps.items.forEach((item) => {
        if (item.prop === "type") item.list = this.typeList
        if (item.prop === "state") item.list = this.stateList
      });
  },
  methods: {
    setInnerHtml(e) {
      this.$nextTick(() => {
        let btnForm = this.$refs.dialogForm.$refs.btnForm;
      });
    },
    dialogForm(title, row = {}) {
      allProps.dialog = {
        isShow: true,
        title,
      };
      row.imgListC=[]
      row.imgList.forEach(item=>{
        row.imgListC.push(item.url)
        })
      allProps.data = { ...row };
    },
    async isAgree(order_id,message) {
       
      let api= message=="同意"?Api.refund.agree:Api.refund.disagree
      let result = await messageBox(api, { order_id },`确定要${message}退款，是否继续？`);
      if (result) this.$refs.AllPage.getTableList();
    },
  },
};
</script>
