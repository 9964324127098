import { Api } from "@/apis";
export const allProps = {
  isShowBtn: {
    addBtn: false,
    dialogFormBtn:"false",
  },
  dialog: {
    isShow: false,
    title: "编辑",
  },
  data: {imgListC:[]},
  items: [
    { label: "id", prop: "id", type: ["table"] },
    {
      label: "创建时间",
      prop: "updated_at",
      type: ["table", "edit"],
      disabled: true,
    },
    {
      label: "订单号",
      prop: "order_id",
      type: ["table", "edit"],
      disabled: true,
    },
    { label: "微信号", prop: "openid", type: ["table"] },
    {
      label: "原因",
      prop: "type",
      type: ["table", "edit"],
      Ttype: "slot",
      Ftype: "radio",
      disabled: true,
      list:[]
    },
    {
      label: "处理状态",
      prop: "state",
      type: ["table", "edit"],
      Ttype: "slot",
      Ftype: "radio",
      disabled: true,
      list:[]
    },
    {
      label: "描述",
      prop: "desc",
      type: ["edit"],
      Ftype: "textarea",
      disabled: true,
    },
    { label: "图片", prop: "imgListC", type: ["edit"], Ftype: "image" },
    {
      label: "操作",
      prop: "operation",
      Ttype: "slot",
      type: ["table"],
      width: "220px",
    },
  ],
  api: Api.refund,
};
